import React from "react";
import { Link } from "gatsby";
import GatsbyImage from "gatsby-image";

//components
import useSetPageData from "../utils/useSetPageData";
import SEO from "../components/seo";

//images
import image4042 from "../images/404-error-2.png";

//styles
import s from "./404.module.scss";

const NotFoundPage = ({ location, data }) => {
  useSetPageData(location);

  return (
    <>
      <SEO
        {...{
          title: `404`,
          description: `The page you are looking for cannot be found.`
        }}
      />
      <div className={`container-fluid panel-padding`}>
        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-sm-12`}>
              <div className={`${s.vertCenter} text-center`}>
                <h1 className={`mb-0 text-center`}>404: NOT FOUND</h1>
                <p style={{ width: "auto" }}>
                  Something went wrong and we can't find that page.
                  {/* You just hit a route that doesn&#39;t exist... the sadness. */}
                </p>
                <p style={{ width: "auto" }}>
                  <Link to={"/"} className={`btn btn-primary`}>
                    Go back to homepage
                  </Link>
                </p>
                <br />
              </div>
            </div>
            <div className={`col-sm-12`}>
              <img
                src={image4042}
                style={{ width: "80%", marginLeft: "10%" }}
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default NotFoundPage;
